import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './bool.module.scss';

export interface BoolConfig {
  id: string;
}

/* eslint-disable-next-line */
export interface BoolProps {
  config: BoolConfig;
  value: boolean;
  onChange: (newValue: boolean) => void;
}

export function Bool({ config, onChange, value }: BoolProps) {
  const { t, i18n } = useTranslation();

  const radioName = config.id;

  return (
    <ToggleButtonGroup
      className="col-7"
      type="radio"
      name={radioName}
      value={value ? '1' : ''}
      onChange={(e) => onChange(!!e)}
    >
      <ToggleButton
        type="radio"
        value={'1'}
        id={radioName + '_yes'}
        variant="outline-secondary"
      >
        {t('yes')}
      </ToggleButton>
      <ToggleButton
        type="radio"
        value={''}
        id={radioName + '_no'}
        variant="outline-secondary"
      >
        {t('no')}{' '}
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default Bool;
