/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { Cartridge } from '../config-types';
import './cartridge-picker.module.scss';

/* eslint-disable-next-line */
export interface CartridgePickerProps {
  onChange: (cartridge: Cartridge) => void;
  value: Cartridge;
  options: Cartridge[];
}

export function CartridgePicker({
  options,
  onChange,
  value,
}: CartridgePickerProps) {
  function scrollOptionIntoView(button: HTMLElement) {
    if (window.innerWidth > 992) {
      const container = button.offsetParent as HTMLElement;
      container.scrollTop = 0;
      container.scrollTop = button.offsetTop / 2;
    }
  }

  return (
      <div className="position-relative col-7">
        <span className="select-icon arrow" />
        <span className="select-icon arrow" />
        <select name="cartridgePicker"
              value={value.id}
              onChange={(cId) => onChange(options.find((o) => o.id === cId.currentTarget.value)!)}
              className={"form-select select-without-icon"}>
        {options.map((cartridge) => {
          return (
            <option
              key={cartridge.id}
              id={`area_${cartridge.id}`}
              value={cartridge.id}
            >
                {cartridge.meta.ui_name}
            </option>
          );
          })}
        </select>
      </div>

  );
}

export default CartridgePicker;
