import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';

import './i18n';

import App from './app/app';
import { DefaultLocaleRedirector } from '@nlg-demo-and-roi-calculator/shared';
import { BrowserRouter } from 'react-router-dom';

const defaultLocale = 'de';

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <DefaultLocaleRedirector defaultLocale={defaultLocale}>
        <App />
      </DefaultLocaleRedirector>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root')
);
