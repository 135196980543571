import { UiDataPointConfig } from '../config-types';
import './data-point.module.scss';
import { Controls } from '@nlg-demo-and-roi-calculator/shared';

const { Bool, List, MultiList, Slider } = Controls;

/* eslint-disable-next-line */
export interface DataPointProps {
  dataPoint: UiDataPointConfig;
  value: any;
  onChange: (newValue: any) => void;
}

export function DataPoint({ dataPoint, value, onChange }: DataPointProps) {
  switch (dataPoint.type) {
    case 'bool':
      return <Bool config={dataPoint} value={value} onChange={onChange}></Bool>;
    case 'list':
      console.log(dataPoint)
      return <List config={dataPoint} value={value} onChange={onChange}></List>;
    case 'multi_list':
      return (
        <MultiList
          config={dataPoint}
          value={value}
          onChange={onChange}
        ></MultiList>
      );
    case 'slider':
      return (
        <Slider config={dataPoint} value={value} onChange={onChange}></Slider>
      );
    default:
      return <>Not Supported data point type</>;
  }
}

export default DataPoint;
