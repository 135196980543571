import { DropdownButton, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ListOption, optionAsObject } from '../shared';
import './multi-list.module.scss';
import { v4 as uuid } from 'uuid';
import { useState } from 'react';

export interface MultiListConfig {
  options: string[] | ListOption[];
}

/* eslint-disable-next-line */
export interface MultiListProps {
  config: MultiListConfig;
  value: any[];
  onChange: (newValue: any) => void;
}

export function MultiList({ config, value, onChange }: MultiListProps) {
  const { t, i18n } = useTranslation();

  const [id] = useState(`multi-list-${uuid()}`);

  function getUpdatedValue(optionId: string, newValue: boolean) {
    if (newValue) {
      return [...value.filter((v) => v !== optionId), optionId];
    } else {
      return value.filter((v) => v !== optionId);
    }
  }

  return (
    <div className="position-relative col-7">
      <span className="select-icon arrow" />
    <DropdownButton
        variant=""
        id={id}
        title={t('controls.multi_list.placeholder')}
      >
        {config.options.map(optionAsObject).map((option) => (
          <Form.Check
            className="mx-3"
            type="checkbox"
            key={option.id}
            id={`${id}-${option.id}`}
            label={option.label}
            checked={value.includes(option.id)}
            onChange={(c) => {
              const newValue = c.target.checked;
              onChange(getUpdatedValue(option.id, newValue));
            }}
          />
        ))}
      </DropdownButton>
    </div>
  );
}

export default MultiList;
