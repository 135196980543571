import { Form } from 'react-bootstrap';
import { ListOption, optionAsObject } from '../shared';
import './list.module.scss';

export interface ListConfig {
  options: string[] | ListOption[];
  icon_class: string;
}

/* eslint-disable-next-line */
export interface ListProps {
  config: ListConfig;
  value: any;
  onChange: (newValue: any) => void;
}

export function List({ config, value, onChange }: ListProps) {
  return (
    <div className="position-relative col-7">
      <span className={config.icon_class} />
      <span className="select-icon arrow" />
    <Form.Select
      className="form-select select-with-icon"
      value={value}
      onChange={(e) => onChange((e.target as HTMLSelectElement).value)}
    >
      {config.options.map(optionAsObject).map((option) => {
        return <option key={option.id}>{option.label}</option>;
      })}
    </Form.Select>
    </div>
  );
}

export default List;
