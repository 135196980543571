/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from 'react';
import styles from './app.module.scss';
import { readConfig } from './confg-reader';
import { Cartridge, Cartridge as CartridgeData } from './config-types';

import { useParams } from 'react-router-dom';

import { generateText } from './generate-text';
import DataPoint from './data-point/data-point';
import * as urls from './urls-config';
import { useTranslation } from 'react-i18next';
import CartridgePicker from './cartridge-picker/cartridge-picker';
import {Controls, media} from '@nlg-demo-and-roi-calculator/shared';
const { List } = Controls;

function getDefaultCartridgeOptionValues(cartridge: Cartridge) {
  return Object.fromEntries(
    cartridge.uiOptions.map((dataPoint) => {
      return [dataPoint.id, cartridge.dataDefaults[dataPoint.id]];
    })
  );
}

export interface UrlParams {
  localeId: string;
}

export function App() {
  const { t, i18n } = useTranslation();
  const { localeId } = useParams<UrlParams>();

  const config = readConfig();

  const localeConfig = config.find(
    (localeConfig) => localeConfig.locale === localeId
  )!;

  const [cartridge, setCartridge] = useState<CartridgeData>(
    localeConfig.cartridges[0]
  );
  const [cartridgeOptionValues, setCartridgeOptionValues] = useState<
    Record<string, any>
  >(getDefaultCartridgeOptionValues(cartridge));
  const [generatedText, setGeneratedText] = useState<string>();

  const [textGenerating, setTextGenerating] = useState<boolean>(false);

  function setOptionValue(id: string, value: any) {
    setCartridgeOptionValues({ ...cartridgeOptionValues, [id]: value });
  }

  function setCartridgeAndRunSideEffects(cartridge: Cartridge) {
    setCartridge(cartridge);
    setCartridgeOptionValues(getDefaultCartridgeOptionValues(cartridge));
  }

  async function regenerateText() {
    setTextGenerating(true);
    const { text } = await generateText(cartridge, cartridgeOptionValues || {});
    setGeneratedText(text);
    setTextGenerating(false);
  }

  useEffect(() => {
    i18n.changeLanguage(localeId);
  }, []);

  // Regenerate text on selected cartridge change
  useEffect(() => {
    regenerateText();
  }, [cartridge]);

  return (
    <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="row textgenerator align-items-center">
              <div className="px-3">
                <div className="d-flex mb-4 align-items-center">
                  <div className="col">
                    <label className="col-form-label small text-dark">{t('nlgCategory')}</label>
                  </div>
                  <CartridgePicker
                    options={localeConfig.cartridges}
                    onChange={setCartridgeAndRunSideEffects}
                    value={cartridge}
                  ></CartridgePicker>
                </div>
                <hr />
                <section
                  id="options"
                >
                  <div className="row">
                    {cartridge.uiOptions.map((dp) => (
                      <div className="d-flex justify-content-center align-content-center align-items-center mb-4" key={dp.id}>
                        <div className="col">
                          <label className="col-form-label small text-dark">{dp.ui_name}</label>
                        </div>
                        <DataPoint
                          dataPoint={dp}
                          value={cartridgeOptionValues[dp.id]}
                          onChange={(newValue) => setOptionValue(dp.id, newValue)}
                        ></DataPoint>
                      </div>
                    ))}
                    <div className="col-12 d-grid mb-3">
                      <a href="#textwasgenerated"
                         onClick={(e) => {
                           e.preventDefault();
                           regenerateText();
                         }}
                         className="btn btn-secondary w-100 mt-4 mb-3">{t('refreshText')}</a>
                      <a href={t('bookADemoUrl')}
                         target="_blank"
                         className="btn btn-primary w-100 mb-5">{t('bookADemo')}</a>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-8 bg-blue-ultralight px-5 min-vh-100 pb-5">
            <div className="col-12">
              <p className="text-muted py-5 d-md-none d-lg-none d-xl-none d-xx-none">
                  {t('autoGeneratedTextHeader')}
              </p>
                {generatedText && (
                  <div
                    dangerouslySetInnerHTML={{ __html: generatedText }}
                  ></div>
                )}
              </div>
          </div>
        </div>
    </div>
  );
}

export default App;
