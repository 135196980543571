import { Toast, ToastBody, ToastContainer } from 'react-bootstrap';
import './toasts.module.scss';

export interface ToastMessage {
  message: string;
  variation?: string;
}

/* eslint-disable-next-line */
export interface ToastsProps {
  messages: ToastMessage[];
  hideMessage: (index: number) => void;
}

export function Toasts({ messages, hideMessage }: ToastsProps) {
  return (
    <ToastContainer position="bottom-center">
      {messages.map((toast, i) => (
        <Toast
          key={i}
          bg={toast.variation}
          autohide
          onClose={() => hideMessage(i)}
        >
          <ToastBody>{toast.message}</ToastBody>
        </Toast>
      ))}
    </ToastContainer>
  );
}

export default Toasts;
