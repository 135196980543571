import './slider.module.scss';
import NoUiSlider from 'nouislider-react';
import wNumb from 'wnumb';

import 'nouislider/distribute/nouislider.css';
import './nouislider-overrides.css';

export interface SliderConfig {
  min: number;
  max: number;
  step?: number;
  decimals?: number;
}

/* eslint-disable-next-line */
export interface SliderProps {
  config: SliderConfig;
  value: number;
  onChange: (newValue: number) => void;
}

export function Slider({ config, value, onChange }: SliderProps) {
  return (
    <NoUiSlider
      className="col-7 pe-4"
      range={{ min: config.min, max: config.max }}
      start={value}
      connect={'lower' as any}
      tooltips={[wNumb({ decimals: config.decimals ?? 0 })]}
      step={config.step ?? 1}
      format={wNumb({ decimals: config.decimals ?? 0 })}
      onChange={(e) => onChange(parseFloat(e[0]))}
    ></NoUiSlider>
  );
}

export default Slider;
