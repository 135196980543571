export interface ListOption {
  id: string;
  label: string;
}

export function optionAsObject(option: string | ListOption) {
  if (typeof option !== 'object') {
    return {
      id: option,
      label: option,
    };
  } else {
    return option;
  }
}
