import { Redirect, Route, Switch } from 'react-router-dom';

/* eslint-disable-next-line */
export interface DefaultLocaleRedirectorProps {
  defaultLocale: string;
  children: React.ReactNode;
}

export function DefaultLocaleRedirector({
  defaultLocale,
  children,
}: DefaultLocaleRedirectorProps) {
  return (
    <Switch>
      <Route path="/:localeId">{children}</Route>
      <Route path="*">
        <Redirect to={defaultLocale}></Redirect>
      </Route>
    </Switch>
  );
}

export default DefaultLocaleRedirector;
