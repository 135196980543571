import { RequestBody, ResponseBody } from './api-types';
import { Cartridge } from './config-types';

export async function generateText(
  cartridge: Cartridge,
  dataPointValues: Record<string, any>
) {
  const response = await fetch(`api/v1/cartridges/${cartridge.id}/generate`, {
    method: 'post',
    body: JSON.stringify({
      data: {
        ...cartridge.dataDefaults,
        ...dataPointValues,
      },
      output_format: 'HTML',
    } as RequestBody),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return (await response.json()) as ResponseBody;
}
